<template>
  <div class="main-wrapper">
    <title-bar
      title="Terms of Use"
      subtitle=""
    />
    <div class="content pt-0">
      <div class="about-wrapper">
        <div class="mt-5">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 col-md-12 ">
                <div class="about-info">
                  <div class="about-desc">
                    <p
                      class="mb-0 font-weight-normal text-justify"
                      style="font-size:20px;"
                    >
                      The Thriggle.com website is provided to you by Thriggle("we" or "us").
                      This Terms of Use Agreement is a binding legal contract between you and us. 
                      Please read this Terms of Use Agreement; by using this service you accept its terms 
                      as well as any additional terms or conditions presented to you during the course of 
                      your use of our service. The Internet is an evolving medium; we may change the terms
                      of this agreement from time to time. By continuing to use the service after we post
                      such changes, you accept this agreement, as modified. We reserve the right to deny
                      access to this website, or any service provided via this website, to anyone who
                      violates this Terms of Use Agreement or who, in our judgment, interferes with the
                      ability of others to enjoy this website, or infringes the rights of others.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TitleBar from 'Components/globalFrontendComponents/TitleBar';
export default {
	components: {
		TitleBar: TitleBar
	}
};
</script>
